import * as React from "react"
import Update from '../components/Update'
import { useStaticQuery, graphql } from "gatsby"

export default function UpdatesSection () {

  // = = = = = = = = = = = = =
  // GraphQL
  const data = useStaticQuery(graphql`
    query Featured {
      featured: allMarkdownRemark(
        filter: {frontmatter: {update_tags: {eq: "featured"}}}
        limit: 3
      ) {
        nodes {
          frontmatter {
            title
            update_image
            update_headline
            update_intro
          }
          id
        }
      }
    }
  `);

  const featured = data.featured.nodes;
  
  return(
    <section id="updates" className="updates">
      
      <div className="container">

        <div className="row">

          <div className="col-md-7">

            <Update 
              key={ featured[0].id } 
              id={ featured[0].id } 
              image={`../${featured[0].frontmatter.update_image}`} 
              headline={ featured[0].frontmatter.update_headline } 
              intro={ featured[0].frontmatter.update_intro } 
              main={ true }
            />
            
          </div>

          <div className="col-md-1">
            <hr className="col-divider"></hr>
          </div>

          <div className="col-md-4">
            
            { featured.slice(1).map( featured => 
                <Update 
                  key={ featured.id } 
                  id={ featured.id } 
                  image={`../${featured.frontmatter.update_image}`} 
                  headline={ featured.frontmatter.update_headline } 
                  intro={ featured.frontmatter.update_intro } 
                /> 
              ) 
            }
            
          </div>
          </div>
      </div>

    </section>
  )
}