import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import Tags from '../components/Tags'
import UpdatesSection from '../components/UpdatesSection'
import SignUpSection from '../components/SignUpSection'
import PaginatedUpdates from '../components/PaginatedUpdates'

export default function updates({ data }) {

  // = = = = = = = = 
  // Forestry Data
  const updatesData = data.updates.frontmatter;

  return(
    <>
      <Seo title="Updates" />

      <Hero
        headline = { updatesData.hero_headline }
        size = {'medium'}
      >
        <StaticImage 
            className="hero__picture"
            src="../../static/media/updates-hero.jpg" 
            alt="" 
            loading="eager"
        />
      </Hero>
      
      <Tags />

      <UpdatesSection/>

      <SignUpSection />
      
      <PaginatedUpdates />
    </>
  )
}

// = = = = = = = = 
// GraphQL
export const data = graphql`
  query UpdatesQuery {
    updates: markdownRemark(frontmatter: {page_title: {eq: "Updates"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_background_image{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;