import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default function Tags() {
  // = = = = = = = = = = = = =
  // GraphQL
  const data = useStaticQuery(graphql`
    query TagsQuery {
      updateTags: allMarkdownRemark {
        nodes {
          frontmatter {
            update_tags
          }
        }
      }
    }
  `);



  // = = = = = = = = = = = = =
  // Hooks
  let tags = [];

  const tagsQuery = data.updateTags.nodes.forEach(node => {

    const tagsArray = node.frontmatter.update_tags;

    if(tagsArray) {

      tagsArray.forEach(tag => {
        if(!tags.includes(tag)) {
          tags.push(tag);
        }
      });
    }
  });

  return (
    <div className="container">
      {console.log(tags)}
      <div className="row">
        <div className="col-md-12">

          <div className="tags-wrapper">

            <ul className="tags-list">
              {
                tags.map(tag => (
                  <li key={tag} className="tag">
                    <Link to={`/updates/${tag}`}>{tag}</Link>
                  </li>
                ))
              }
            </ul>

          </div>

        </div>
      </div>
    </div>
  )
}